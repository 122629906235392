export enum DocsEnum {
  OFFER = "offer",
  TERMS = "terms-conditions",
  DETAILS = "details-non-residents",
  SCC = "scc",
  SHARING = "data-sharing",
  PRIVACY = "privacy-policy",
}

export const DOCS_INFO = {
  [DocsEnum.OFFER]: "Offer for publishers",
  [DocsEnum.TERMS]: "Terms and conditions for publishers",
  [DocsEnum.DETAILS]: "Details of the Company for non-residents",
  [DocsEnum.SCC]: "Standard contractual clauses",
  [DocsEnum.SHARING]: "Data Sharing Addendum",
  [DocsEnum.PRIVACY]: "Privacy Policy",
}
